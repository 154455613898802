videojs.addLanguage("fa",{
 "Audio Player": "پخش کننده صوتی",
 "Video Player": "پخش کننده ویدیو",
 "Play": "پخش",
 "Pause": "مکث",
 "Replay": "بازپخش",
 "Current Time": "زمان کنونی",
 "Duration Time": "مدت زمان",
 "Remaining Time": "زمان باقیمانده",
 "Stream Type": "نوع استریم",
 "LIVE": "زنده",
 "Loaded": "بارگیری شده",
 "Progress": "پیشرفت",
 "Progress Bar": "نوار پیشرفت",
 "progress bar timing: currentTime={1} duration={2}": "{1} از {2}",
 "Fullscreen": "تمام‌صفحه",
 "Non-Fullscreen": "غیر تمام‌صفحه",
 "Mute": "بی صدا",
 "Unmute": "صدا دار",
 "Playback Rate": "سرعت پخش",
 "Subtitles": "زیرنویس",
 "subtitles off": "بدون زیرنویس",
 "Captions": "زیرتوضیح",
 "captions off": "بدون زیرتوضیح",
 "Chapters": "قسمت‌ها",
 "Descriptions": "توصیف",
 "descriptions off": "بدون توصیف",
 "Audio Track": "صوت",
 "Volume Level": "میزان صدا",
 "You aborted the media playback": "شما پخش را قطع کردید.",
 "A network error caused the media download to fail part-way.": "خطای شبکه باعث عدم بارگیری بخشی از رسانه شد.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": ".رسانه قابل بارگیری نیست.   علت آن ممکن است خطا در اتصال یا عدم پشتیبانی از فرمت باشد",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "پخش  رسانه به علت اشکال در آن یا عدم پشتیبانی مرورگر شما قطع شد.",
 "No compatible source was found for this media.": "هیچ منبع سازگاری، برای این رسانه پیدا نشد.",
 "The media is encrypted and we do not have the keys to decrypt it.": "این رسانه رمزنگاری شده است و ما کلید رمزگشایی آن را نداریم.",
 "Play Video": "پخش ویدیو",
 "Close": "بستن",
 "Close Modal Dialog": "بستن پنجره مودال",
 "Modal Window": "پنجره مودال",
 "This is a modal window": "این پنجره مودال",
 "This modal can be closed by pressing the Escape key or activating the close button.": "این پنجره با دکمه اسکیپ با دکمه بستن قابل بسته شدن میباشد.",
 ", opens captions settings dialog": ", تنظیمات زیرتوضیح را باز میکند",
 ", opens subtitles settings dialog": ", تنظیمات زیرنویس را باز میکند",
 ", opens descriptions settings dialog": ", تنظیمات توصیفات را باز میکند",
 ", selected": ", انتخاب شده",
 "captions settings": "تنظیمات زیرتوضیح",
 "subtitles settings": "تنظیمات زیرنویس",
 "descriptions settings": "تنظیمات توصیفات",
 "Text": "متن",
 "White": "سفید",
 "Black": "سیاه",
 "Red": "قرمز",
 "Green": "سبز",
 "Blue": "آبی",
 "Yellow": "زرد",
 "Magenta": "ارغوانی",
 "Cyan": "سبزآبی",
 "Background": "زمینه",
 "Window": "پنجره",
 "Transparent": "شفاف",
 "Semi-Transparent": "نیمه شفاف",
 "Opaque": "مات",
 "Font Size": "اندازه فونت",
 "Text Edge Style": "سبک لبه متن",
 "None": "هیچ",
 "Raised": "برآمده",
 "Depressed": "فرورفته",
 "Uniform": "یکنواخت",
 "Dropshadow": "سایه دار",
 "Font Family": "نوع فونت",
 "Proportional Sans-Serif": "سنس-سریف متناسب",
 "Monospace Sans-Serif": "سنس-سریف هم اندازه",
 "Proportional Serif": "سریف متناسب",
 "Monospace Serif": "سریف هم اندازه",
 "Casual": "فانتزی",
 "Script": "دست خط",
 "Small Caps": "حروف کوچک به بزرگ",
 "Reset": "باز نشاندن",
 "restore all settings to the default values": "بازیابی همه تنظیمات به حالت اولیه",
 "Done": "تکمیل",
 "Caption Settings Dialog": "پنجره تنظیمات عناوین",
 "Beginning of dialog window. Escape will cancel and close the window.": "ابتدای پنجره محاوره‌ای. دکمه اسکیپ پنجره را لغو میکند و میبندد.",
 "End of dialog window.": "انتهای پنجره محاوره‌ای."
});