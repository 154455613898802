videojs.addLanguage("pt-PT",{
 "Play": "Reproduzir",
 "Pause": "Parar",
 "Replay": "Reiniciar",
 "Current Time": "Tempo Atual",
 "Duration Time": "Duração",
 "Remaining Time": "Tempo Restante",
 "Stream Type": "Tipo de Stream",
 "LIVE": "EM DIRETO",
 "Loaded": "Carregado",
 "Progress": "Progresso",
 "Fullscreen": "Ecrã inteiro",
 "Non-Fullscreen": "Ecrã normal",
 "Mute": "Desativar som",
 "Unmute": "Ativar som",
 "Playback Rate": "Velocidade de reprodução",
 "Subtitles": "Legendas",
 "subtitles off": "desativar legendas",
 "Captions": "Anotações",
 "captions off": "desativar anotações",
 "Chapters": "Capítulos",
 "Close Modal Dialog": "Fechar Janela Modal",
 "Descriptions": "Descrições",
 "descriptions off": "desativar descrições",
 "Audio Track": "Faixa Áudio",
 "You aborted the media playback": "Parou a reprodução do vídeo.",
 "A network error caused the media download to fail part-way.": "Um erro na rede fez o vídeo falhar parcialmente.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "O vídeo não pode ser carregado, ou porque houve um problema na rede ou no servidor, ou porque formato do vídeo não é compatível.",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "A reprodução foi interrompida por um problema com o vídeo ou porque o formato não é compatível com o seu navegador.",
 "No compatible source was found for this media.": "Não foi encontrada uma fonte de vídeo compatível.",
 "The media is encrypted and we do not have the keys to decrypt it.": "O vídeo está encriptado e não há uma chave para o desencriptar.",
 "Play Video": "Reproduzir Vídeo",
 "Close": "Fechar",
 "Modal Window": "Janela Modal",
 "This is a modal window": "Isto é uma janela modal",
 "This modal can be closed by pressing the Escape key or activating the close button.": "Esta modal pode ser fechada pressionando a tecla ESC ou ativando o botão de fechar.",
 ", opens captions settings dialog": ", abre janela com definições de legendas",
 ", opens subtitles settings dialog": ", abre janela com definições de legendas",
 ", opens descriptions settings dialog": ", abre janela com definições de descrições",
 ", selected": ", seleccionado"
});