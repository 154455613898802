videojs.addLanguage("he",{
 "Audio Player": "נַגָּן שמע",
 "Video Player": "נַגָּן וידאו",
 "Play": "נַגֵּן",
 "Pause": "השהה",
 "Replay": "נַגֵּן שוב",
 "Current Time": "זמן נוכחי",
 "Duration Time": "זמן כולל",
 "Remaining Time": "זמן נותר",
 "Stream Type": "סוג Stream",
 "LIVE": "שידור חי",
 "Loaded": "נטען",
 "Progress": "התקדמות",
 "Progress Bar": "סרגל התקדמות",
 "progress bar timing: currentTime={1} duration={2}": "{1} מתוך {2}",
 "Fullscreen": "מסך מלא",
 "Non-Fullscreen": "מסך לא מלא",
 "Mute": "השתק",
 "Unmute": "בטל השתקה",
 "Playback Rate": "קצב ניגון",
 "Subtitles": "כתוביות",
 "subtitles off": "כתוביות כבויות",
 "Captions": "כיתובים",
 "captions off": "כיתובים כבויים",
 "Chapters": "פרקים",
 "Descriptions": "תיאורים",
 "descriptions off": "תיאורים כבויים",
 "Audio Track": "רצועת שמע",
 "Volume Level": "רמת ווליום",
 "You aborted the media playback": "ביטלת את השמעת המדיה",
 "A network error caused the media download to fail part-way.": "שגיאת רשת גרמה להורדת המדיה להיכשל באמצע.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "לא ניתן לטעון את המדיה, או מכיוון שהרשת או השרת כשלו או מכיוון שהפורמט אינו נתמך.",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "השמעת המדיה בוטלה בשל בעית השחטת מידע או מכיוון שהמדיה עשתה שימוש בתכונות שהדפדפן שלך לא תמך בהן.",
 "No compatible source was found for this media.": "לא נמצא מקור תואם עבור מדיה זו.",
 "The media is encrypted and we do not have the keys to decrypt it.": "המדיה מוצפנת ואין בידינו את המפתח כדי לפענח אותה.",
 "Play Video": "נַגֵּן וידאו",
 "Close": "סְגוֹר",
 "Close Modal Dialog": "סְגוֹר דו-שיח מודאלי",
 "Modal Window": "חלון מודאלי",
 "This is a modal window": "זהו חלון מודאלי",
 "This modal can be closed by pressing the Escape key or activating the close button.": "ניתן לסגור חלון מודאלי זה ע\"י לחיצה על כפתור ה-Escape או הפעלת כפתור הסגירה.",
 ", opens captions settings dialog": ", פותח חלון הגדרות כיתובים",
 ", opens subtitles settings dialog": ", פותח חלון הגדרות כתוביות",
 ", opens descriptions settings dialog": ", פותח חלון הגדרות תיאורים",
 ", selected": ", נבחר/ו",
 "captions settings": "הגדרות כיתובים",
 "subtitles settings": "הגדרות כתוביות",
 "descriptions settings": "הגדרות תיאורים",
 "Text": "טקסט",
 "White": "לבן",
 "Black": "שחור",
 "Red": "אדום",
 "Green": "ירוק",
 "Blue": "כחול",
 "Yellow": "צהוב",
 "Magenta": "מַגֶ'נטָה",
 "Cyan": "טורקיז",
 "Background": "רקע",
 "Window": "חלון",
 "Transparent": "שקוף",
 "Semi-Transparent": "שקוף למחצה",
 "Opaque": "אָטוּם",
 "Font Size": "גודל גופן",
 "Text Edge Style": "סגנון קצוות טקסט",
 "None": "ללא",
 "Raised": "מורם",
 "Depressed": "מורד",
 "Uniform": "אחיד",
 "Dropshadow": "הטלת צל",
 "Font Family": "משפחת גופן",
 "Proportional Sans-Serif": "פרופורציוני וללא תגיות (Proportional Sans-Serif)",
 "Monospace Sans-Serif": "ברוחב אחיד וללא תגיות (Monospace Sans-Serif)",
 "Proportional Serif": "פרופורציוני ועם תגיות (Proportional Serif)",
 "Monospace Serif": "ברוחב אחיד ועם תגיות (Monospace Serif)",
 "Casual": "אַגָבִי",
 "Script": "תסריט",
 "Small Caps": "אותיות קטנות",
 "Reset": "אִפּוּס",
 "restore all settings to the default values": "שחזר את כל ההגדרות לערכי ברירת המחדל",
 "Done": "בוצע",
 "Caption Settings Dialog": "דו-שיח הגדרות כיתובים",
 "Beginning of dialog window. Escape will cancel and close the window.": "תחילת חלון דו-שיח. Escape יבטל ויסגור את החלון",
 "End of dialog window.": "סוף חלון דו-שיח."
});