videojs.addLanguage("tr",{
 "Play": "Oynat",
 "Pause": "Duraklat",
 "Replay": "Yeniden Oynat",
 "Current Time": "Süre",
 "Duration Time": "Toplam Süre",
 "Remaining Time": "Kalan Süre",
 "Stream Type": "Yayın Tipi",
 "LIVE": "CANLI",
 "Loaded": "Yüklendi",
 "Progress": "Yükleniyor",
 "Fullscreen": "Tam Ekran",
 "Non-Fullscreen": "Küçük Ekran",
 "Mute": "Ses Kapa",
 "Unmute": "Ses Aç",
 "Playback Rate": "Oynatma Hızı",
 "Subtitles": "Altyazı",
 "subtitles off": "Altyazı Kapalı",
 "Captions": "Altyazı",
 "captions off": "Altyazı Kapalı",
 "Chapters": "Bölümler",
 "Close Modal Dialog": "Dialogu Kapat",
 "Descriptions": "Açıklamalar",
 "descriptions off": "Açıklamalar kapalı",
 "Audio Track": "Ses Dosyası",
 "You aborted the media playback": "Video oynatmayı iptal ettiniz",
 "A network error caused the media download to fail part-way.": "Video indirilirken bağlantı sorunu oluştu.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Video oynatılamadı, ağ ya da sunucu hatası veya belirtilen format desteklenmiyor.",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "Tarayıcınız desteklemediği için videoda hata oluştu.",
 "No compatible source was found for this media.": "Video için kaynak bulunamadı.",
 "The media is encrypted and we do not have the keys to decrypt it.": "Video, şifrelenmiş bir kaynaktan geliyor ve oynatmak için gerekli anahtar bulunamadı.",
 "Play Video": "Videoyu Oynat",
 "Close": "Kapat",
 "Modal Window": "Modal Penceresi",
 "This is a modal window": "Bu bir modal penceresidir",
 "This modal can be closed by pressing the Escape key or activating the close button.": "Bu modal ESC tuşuna basarak ya da kapata tıklanarak kapatılabilir.",
 ", opens captions settings dialog": ", altyazı ayarları menüsünü açar",
 ", opens subtitles settings dialog": ", altyazı ayarları menüsünü açar",
 ", opens descriptions settings dialog": ", açıklama ayarları menüsünü açar",
 ", selected": ", seçildi",
 "captions settings": "altyazı ayarları",
 "subtitles settings": "altyazı ayarları",
 "descriptions settings": "açıklama ayarları",
 "Text": "Yazı",
 "White": "Beyaz",
 "Black": "Siyah",
 "Red": "Kırmızı",
 "Green": "Yeşil",
 "Blue": "Mavi",
 "Yellow": "Sarı",
 "Magenta": "Macenta",
 "Cyan": "Açık Mavi (Camgöbeği)",
 "Background": "Arka plan",
 "Window": "Pencere",
 "Transparent": "Saydam",
 "Semi-Transparent": "Yarı-Saydam",
 "Opaque": "Mat",
 "Font Size": "Yazı Boyutu",
 "Text Edge Style": "Yazı Kenarlıkları",
 "None": "Hiçbiri",
 "Raised": "Kabartılmış",
 "Depressed": "Yassı",
 "Uniform": "Düz",
 "Dropshadow": "Gölgeli",
 "Font Family": "Yazı Tipi",
 "Proportional Sans-Serif": "Orantılı Sans-Serif",
 "Monospace Sans-Serif": "Eşaralıklı Sans-Serif",
 "Proportional Serif": "Orantılı Serif",
 "Monospace Serif": "Eşaralıklı Serif",
 "Casual": "Gündelik",
 "Script": "El Yazısı",
 "Small Caps": "Küçük Boyutlu Büyük Harfli",
 "Done": "Tamam",
 "Caption Settings Dialog": "Altyazı Ayarları Menüsü",
 "Beginning of dialog window. Escape will cancel and close the window.": "Diyalog penceresinin başlangıcı. ESC tuşu işlemi iptal edip pencereyi kapatacaktır."
});