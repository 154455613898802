videojs.addLanguage("ru",{
 "Audio Player": "Аудио проигрыватель",
 "Video Player": "Видео проигрыватель",
 "Play": "Воспроизвести",
 "Pause": "Приостановить",
 "Replay": "Воспроизвести снова",
 "Current Time": "Текущее время",
 "Duration Time": "Продолжительность",
 "Remaining Time": "Оставшееся время",
 "Stream Type": "Тип потока",
 "LIVE": "ОНЛАЙН",
 "Loaded": "Загрузка",
 "Progress": "Прогресс",
 "Progress Bar": "Индикатор загрузки",
 "progress bar timing: currentTime={1} duration={2}": "{1} из {2}",
 "Fullscreen": "Полноэкранный режим",
 "Non-Fullscreen": "Неполноэкранный режим",
 "Mute": "Без звука",
 "Unmute": "Со звуком",
 "Playback Rate": "Скорость воспроизведения",
 "Subtitles": "Субтитры",
 "subtitles off": "Субтитры выкл.",
 "Captions": "Подписи",
 "captions off": "Подписи выкл.",
 "Chapters": "Главы",
 "Descriptions": "Описания",
 "descriptions off": "Отключить описания",
 "Audio Track": "Звуковая дорожка",
 "Volume Level": "Уровень громкости",
 "You aborted the media playback": "Вы прервали воспроизведение видео",
 "A network error caused the media download to fail part-way.": "Ошибка сети вызвала сбой во время загрузки видео.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Невозможно загрузить видео из-за сетевого или серверного сбоя либо формат не поддерживается.",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "Воспроизведение видео было приостановлено из-за повреждения либо в связи с тем, что видео использует функции, неподдерживаемые вашим браузером.",
 "No compatible source was found for this media.": "Совместимые источники для этого видео отсутствуют.",
 "The media is encrypted and we do not have the keys to decrypt it.": "Видео в зашифрованном виде, и у нас нет ключей для расшифровки.",
 "Play Video": "Воспроизвести видео",
 "Close": "Закрыть",
 "Close Modal Dialog": "Закрыть модальное окно",
 "Modal Window": "Модальное окно",
 "This is a modal window": "Это модальное окно",
 "This modal can be closed by pressing the Escape key or activating the close button.": "Модальное окно можно закрыть нажав Esc или кнопку закрытия окна.",
 ", opens captions settings dialog": ", откроется диалог настройки подписей",
 ", opens subtitles settings dialog": ", откроется диалог настройки субтитров",
 ", opens descriptions settings dialog": ", откроется диалог настройки описаний",
 ", selected": ", выбрано",
 "captions settings": "настройки подписей",
 "subtitles settings": "настройки субтитров",
 "descriptions settings": "настройки описаний",
 "Text": "Текст",
 "White": "Белый",
 "Black": "Черный",
 "Red": "Красный",
 "Green": "Зеленый",
 "Blue": "Синий",
 "Yellow": "Желтый",
 "Magenta": "Пурпурный",
 "Cyan": "Голубой",
 "Background": "Фон",
 "Window": "Окно",
 "Transparent": "Прозрачный",
 "Semi-Transparent": "Полупрозрачный",
 "Opaque": "Прозрачность",
 "Font Size": "Размер шрифта",
 "Text Edge Style": "Стиль края текста",
 "None": "Ничего",
 "Raised": "Поднятый",
 "Depressed": "Пониженный",
 "Uniform": "Одинаковый",
 "Dropshadow": "Тень",
 "Font Family": "Шрифт",
 "Proportional Sans-Serif": "Пропорциональный без засечек",
 "Monospace Sans-Serif": "Моноширинный без засечек",
 "Proportional Serif": "Пропорциональный с засечками",
 "Monospace Serif": "Моноширинный с засечками",
 "Casual": "Случайный",
 "Script": "Письменный",
 "Small Caps": "Малые прописные",
 "Reset": "Сбросить",
 "restore all settings to the default values": "сбросить все найстройки по умолчанию",
 "Done": "Готово",
 "Caption Settings Dialog": "Диалог настроек подписи",
 "Beginning of dialog window. Escape will cancel and close the window.": "Начало диалоговго окна. Кнопка Escape закроет или отменит окно",
 "End of dialog window.": "Конец диалогового окна."
});