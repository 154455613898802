videojs.addLanguage("nn",{
 "Play": "Spel",
 "Pause": "Pause",
 "Current Time": "Aktuell tid",
 "Duration Time": "Varigheit",
 "Remaining Time": "Tid attende",
 "Stream Type": "Type straum",
 "LIVE": "DIREKTE",
 "Loaded": "Lasta inn",
 "Progress": "Status",
 "Fullscreen": "Fullskjerm",
 "Non-Fullscreen": "Stenga fullskjerm",
 "Mute": "Ljod av",
 "Unmute": "Ljod på",
 "Playback Rate": "Avspelingsrate",
 "Subtitles": "Teksting på",
 "subtitles off": "Teksting av",
 "Captions": "Teksting for høyrselshemma på",
 "captions off": "Teksting for høyrselshemma av",
 "Chapters": "Kapitel",
 "You aborted the media playback": "Du avbraut avspelinga.",
 "A network error caused the media download to fail part-way.": "Ein nettverksfeil avbraut nedlasting av videoen.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Videoen kunne ikkje lastas ned, på grunn av ein nettverksfeil eller serverfeil, eller av di formatet ikkje er stoda.",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "Videoavspelinga blei broten på grunn av øydelagde data eller av di videoen ville gjera noe som nettlesaren din ikkje stodar.",
 "No compatible source was found for this media.": "Fant ikke en kompatibel kilde for dette mediainnholdet."
});