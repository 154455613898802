videojs.addLanguage("de",{
 "Play": "Wiedergabe",
 "Pause": "Pause",
 "Replay": "Erneut abspielen",
 "Current Time": "Aktueller Zeitpunkt",
 "Duration Time": "Dauer",
 "Remaining Time": "Verbleibende Zeit",
 "Stream Type": "Streamtyp",
 "LIVE": "LIVE",
 "Loaded": "Geladen",
 "Progress": "Status",
 "Fullscreen": "Vollbild",
 "Non-Fullscreen": "Kein Vollbild",
 "Mute": "Ton aus",
 "Unmute": "Ton ein",
 "Playback Rate": "Wiedergabegeschwindigkeit",
 "Subtitles": "Untertitel",
 "subtitles off": "Untertitel aus",
 "Captions": "Untertitel",
 "captions off": "Untertitel aus",
 "Chapters": "Kapitel",
 "You aborted the media playback": "Sie haben die Videowiedergabe abgebrochen.",
 "A network error caused the media download to fail part-way.": "Der Videodownload ist aufgrund eines Netzwerkfehlers fehlgeschlagen.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Das Video konnte nicht geladen werden, da entweder ein Server- oder Netzwerkfehler auftrat oder das Format nicht unterstützt wird.",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "Die Videowiedergabe wurde entweder wegen eines Problems mit einem beschädigten Video oder wegen verwendeten Funktionen, die vom Browser nicht unterstützt werden, abgebrochen.",
 "No compatible source was found for this media.": "Für dieses Video wurde keine kompatible Quelle gefunden.",
 "Play Video": "Video abspielen",
 "Close": "Schließen",
 "Modal Window": "Modales Fenster",
 "This is a modal window": "Dies ist ein modales Fenster",
 "This modal can be closed by pressing the Escape key or activating the close button.": "Durch Drücken der Esc-Taste bzw. Betätigung der Schaltfläche \"Schließen\" wird dieses modale Fenster geschlossen.",
 ", opens captions settings dialog": ", öffnet Einstellungen für Untertitel",
 ", opens subtitles settings dialog": ", öffnet Einstellungen für Untertitel",
 ", selected": ", ausgewählt",
 "captions settings": "Untertiteleinstellungen",
 "subtitles settings": "Untertiteleinstellungen",
 "descriptions settings": "Einstellungen für Beschreibungen",
 "Close Modal Dialog": "Modales Fenster schließen",
 "Descriptions": "Beschreibungen",
 "descriptions off": "Beschreibungen aus",
 "The media is encrypted and we do not have the keys to decrypt it.": "Die Entschlüsselungsschlüssel für den verschlüsselten Medieninhalt sind nicht verfügbar.",
 ", opens descriptions settings dialog": ", öffnet Einstellungen für Beschreibungen",
 "Audio Track": "Tonspur",
 "Text": "Schrift",
 "White": "Weiß",
 "Black": "Schwarz",
 "Red": "Rot",
 "Green": "Grün",
 "Blue": "Blau",
 "Yellow": "Gelb",
 "Magenta": "Magenta",
 "Cyan": "Türkis",
 "Background": "Hintergrund",
 "Window": "Fenster",
 "Transparent": "Durchsichtig",
 "Semi-Transparent": "Halbdurchsichtig",
 "Opaque": "Undurchsictig",
 "Font Size": "Schriftgröße",
 "Text Edge Style": "Textkantenstil",
 "None": "Kein",
 "Raised": "Erhoben",
 "Depressed": "Gedrückt",
 "Uniform": "Uniform",
 "Dropshadow": "Schlagschatten",
 "Font Family": "Schristfamilie",
 "Proportional Sans-Serif": "Proportionale Sans-Serif",
 "Monospace Sans-Serif": "Monospace Sans-Serif",
 "Proportional Serif": "Proportionale Serif",
 "Monospace Serif": "Monospace Serif",
 "Casual": "Zwanglos",
 "Script": "Schreibeschrift",
 "Small Caps": "Small-Caps",
 "Reset": "Zurücksetzen",
 "restore all settings to the default values": "Alle Einstellungen auf die Standardwerte zurücksetzen",
 "Done": "Fertig",
 "Caption Settings Dialog": "Einstellungsdialog für Untertitel",
 "Beginning of dialog window. Escape will cancel and close the window.": "Anfang des Dialogfensters. Esc bricht ab und schließt das Fenster.",
 "End of dialog window.": "Ende des Dialogfensters.",
 "Audio Player": "Audio-Player",
 "Video Player": "Video-Player",
 "Progress Bar": "Forschrittsbalken",
 "progress bar timing: currentTime={1} duration={2}": "{1} von {2}",
 "Volume Level": "Lautstärkestufe"
});