videojs.addLanguage("ar",{
 "Play": "تشغيل",
 "Pause": "إيقاف",
 "Current Time": "الوقت الحالي",
 "Duration Time": "مدة",
 "Remaining Time": "الوقت المتبقي",
 "Stream Type": "نوع التيار",
 "LIVE": "مباشر",
 "Loaded": "تم التحميل",
 "Progress": "التقدم",
 "Fullscreen": "ملء الشاشة",
 "Non-Fullscreen": "تعطيل ملء الشاشة",
 "Mute": "صامت",
 "Unmute": "غير الصامت",
 "Playback Rate": "معدل التشغيل",
 "Subtitles": "الترجمة",
 "subtitles off": "إيقاف الترجمة",
 "Captions": "التعليقات",
 "captions off": "إيقاف التعليقات",
 "Chapters": "فصول",
 "You aborted the media playback": "لقد ألغيت تشغيل الفيديو",
 "A network error caused the media download to fail part-way.": "تسبب خطأ في الشبكة بفشل تحميل الفيديو بالكامل.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "لا يمكن تحميل الفيديو بسبب فشل في الخادوم أو الشبكة ، أو فشل بسبب عدم إمكانية قراءة تنسيق الفيديو.",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "تم إيقاف تشغيل الفيديو بسبب مشكلة فساد أو لأن الفيديو المستخدم يستخدم ميزات غير مدعومة من متصفحك.",
 "No compatible source was found for this media.": "فشل العثور على أي مصدر متوافق مع هذا الفيديو.",
 "Play Video": "تشغيل الفيديو",
 "Close": "أغلق",
 "Modal Window": "نافذة مشروطة",
 "This is a modal window": "هذه نافذة مشروطة",
 "This modal can be closed by pressing the Escape key or activating the close button.": "يمكن غلق هذه النافذة المشروطة عن طريق الضغط على زر الخروج أو تفعيل زر الإغلاق",
 ", opens captions settings dialog": ", تفتح نافذة  خيارات التعليقات",
 ", opens subtitles settings dialog": ", تفتح نافذة  خيارات الترجمة",
 ", selected": ", مختار"
});