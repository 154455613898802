videojs.addLanguage("vi",{
 "Audio Player": "Trình phát Audio",
 "Video Player": "Trình phát Video",
 "Play": "Phát",
 "Pause": "Tạm dừng",
 "Replay": "Phát lại",
 "Current Time": "Thời gian hiện tại",
 "Duration Time": "Độ dài",
 "Remaining Time": "Thời gian còn lại",
 "Stream Type": "Kiểu Stream",
 "LIVE": "TRỰC TIẾP",
 "Loaded": "Đã tải",
 "Progress": "Tiến trình",
 "Progress Bar": "Thanh tiến trình",
 "progress bar timing: currentTime={1} duration={2}": "{1} của {2}",
 "Fullscreen": "Toàn màn hình",
 "Non-Fullscreen": "Thoát toàn màn hình",
 "Mute": "Tắt tiếng",
 "Unmute": "Bật âm thanh",
 "Playback Rate": "Tỉ lệ phát lại",
 "Subtitles": "Phụ đề",
 "subtitles off": "tắt phụ đề",
 "Captions": "Chú thích",
 "captions off": "tắt chú thích",
 "Chapters": "Chương",
 "Descriptions": "Mô tả",
 "descriptions off": "tắt mô tả",
 "Audio Track": "Track âm thanh",
 "Volume Level": "Mức âm lượng",
 "You aborted the media playback": "Bạn đã hủy việc phát lại media.",
 "A network error caused the media download to fail part-way.": "Một lỗi mạng dẫn đến việc tải media bị lỗi.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Video không tải được, mạng hay server có lỗi hoặc định dạng không được hỗ trợ.",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "Phát media đã bị hủy do một sai lỗi hoặc media sử dụng những tính năng trình duyệt không hỗ trợ.",
 "No compatible source was found for this media.": "Không có nguồn tương thích cho media này.",
 "The media is encrypted and we do not have the keys to decrypt it.": "Media đã được mã hóa và chúng tôi không có để giải mã nó.",
 "Play Video": "Phát Video",
 "Close": "Đóng",
 "Close Modal Dialog": "Đóng cửa sổ",
 "Modal Window": "Cửa sổ",
 "This is a modal window": "Đây là một cửa sổ",
 "This modal can be closed by pressing the Escape key or activating the close button.": "Cửa sổ này có thể thoát bằng việc nhấn phím Esc hoặc kích hoạt nút đóng.",
 ", opens captions settings dialog": ", mở hộp thoại cài đặt chú thích",
 ", opens subtitles settings dialog": ", mở hộp thoại cài đặt phụ đề",
 ", opens descriptions settings dialog": ", mở hộp thoại cài đặt mô tả",
 ", selected": ", đã chọn",
 "captions settings": "cài đặt chú thích",
 "subtitles settings": "cài đặt phụ đề",
 "descriptions settings": "cài đặt mô tả",
 "Text": "Văn bản",
 "White": "Trắng",
 "Black": "Đen",
 "Red": "Đỏ",
 "Green": "Xanh lá cây",
 "Blue": "Xanh da trời",
 "Yellow": "Vàng",
 "Magenta": "Đỏ tươi",
 "Cyan": "Lam",
 "Background": "Nền",
 "Window": "Cửa sổ",
 "Transparent": "Trong suốt",
 "Semi-Transparent": "Bán trong suốt",
 "Opaque": "Mờ",
 "Font Size": "Kích cỡ phông chữ",
 "Text Edge Style": "Dạng viền văn bản",
 "None": "None",
 "Raised": "Raised",
 "Depressed": "Depressed",
 "Uniform": "Uniform",
 "Dropshadow": "Dropshadow",
 "Font Family": "Phông chữ",
 "Proportional Sans-Serif": "Proportional Sans-Serif",
 "Monospace Sans-Serif": "Monospace Sans-Serif",
 "Proportional Serif": "Proportional Serif",
 "Monospace Serif": "Monospace Serif",
 "Casual": "Casual",
 "Script": "Script",
 "Small Caps": "Small Caps",
 "Reset": "Đặt lại",
 "restore all settings to the default values": "khôi phục lại tất cả các cài đặt về giá trị mặc định",
 "Done": "Xong",
 "Caption Settings Dialog": "Hộp thoại cài đặt chú thích",
 "Beginning of dialog window. Escape will cancel and close the window.": "Bắt đầu cửa sổ hộp thoại. Esc sẽ thoát và đóng cửa sổ.",
 "End of dialog window.": "Kết thúc cửa sổ hộp thoại."
});