videojs.addLanguage("pl",{
 "Play": "Odtwarzaj",
 "Pause": "Pauza",
 "Current Time": "Aktualny czas",
 "Duration Time": "Czas trwania",
 "Remaining Time": "Pozostały czas",
 "Stream Type": "Typ strumienia",
 "LIVE": "NA ŻYWO",
 "Loaded": "Załadowany",
 "Progress": "Status",
 "Fullscreen": "Pełny ekran",
 "Non-Fullscreen": "Pełny ekran niedostępny",
 "Mute": "Wyłącz dźwięk",
 "Unmute": "Włącz dźwięk",
 "Playback Rate": "Szybkość odtwarzania",
 "Subtitles": "Napisy",
 "subtitles off": "Napisy wyłączone",
 "Captions": "Transkrypcja",
 "captions off": "Transkrypcja wyłączona",
 "Chapters": "Rozdziały",
 "You aborted the media playback": "Odtwarzanie zostało przerwane",
 "A network error caused the media download to fail part-way.": "Problemy z siecią spowodowały błąd przy pobieraniu materiału wideo.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Materiał wideo nie może być załadowany, ponieważ wystąpił problem z siecią lub format nie jest obsługiwany",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "Odtwarzanie materiału wideo zostało przerwane z powodu uszkodzonego pliku wideo lub z powodu błędu funkcji, które nie są wspierane przez przeglądarkę.",
 "No compatible source was found for this media.": "Dla tego materiału wideo nie znaleziono kompatybilnego źródła.",
 "Play Video": "Odtwarzaj wideo",
 "Close": "Zamknij",
 "Modal Window": "Okno Modala",
 "This is a modal window": "To jest okno modala",
 "This modal can be closed by pressing the Escape key or activating the close button.": "Ten modal możesz zamknąć naciskając przycisk Escape albo wybierając przycisk Zamknij.",
 ", opens captions settings dialog": ", otwiera okno dialogowe ustawień transkrypcji",
 ", opens subtitles settings dialog": ", otwiera okno dialogowe napisów",
 ", selected": ", zaznaczone"
});