videojs.addLanguage("uk",{
 "Play": "Відтворити",
 "Pause": "Призупинити",
 "Current Time": "Поточний час",
 "Duration Time": "Тривалість",
 "Remaining Time": "Час, що залишився",
 "Stream Type": "Тип потоку",
 "LIVE": "НАЖИВО",
 "Loaded": "Завантаження",
 "Progress": "Прогрес",
 "Fullscreen": "Повноекранний режим",
 "Non-Fullscreen": "Неповноекранний режим",
 "Mute": "Без звуку",
 "Unmute": "Зі звуком",
 "Playback Rate": "Швидкість відтворення",
 "Subtitles": "Субтитри",
 "subtitles off": "Без субтитрів",
 "Captions": "Підписи",
 "captions off": "Без підписів",
 "Chapters": "Розділи",
 "Close Modal Dialog": "Закрити модальний діалог",
 "Descriptions": "Описи",
 "descriptions off": "Без описів",
 "Audio Track": "Аудіодоріжка",
 "You aborted the media playback": "Ви припинили відтворення відео",
 "A network error caused the media download to fail part-way.": "Помилка мережі викликала збій під час завантаження відео.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Неможливо завантажити відео через мережевий чи серверний збій або формат не підтримується.",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "Відтворення відео було припинено через пошкодження або у зв'язку з тим, що відео використовує функції, які не підтримуються вашим браузером.",
 "No compatible source was found for this media.": "Сумісні джерела для цього відео відсутні.",
 "The media is encrypted and we do not have the keys to decrypt it.": "Відео в зашифрованому вигляді, і ми не маємо ключі для розшифровки.",
 "Play Video": "Відтворити відео",
 "Close": "Закрити",
 "Modal Window": "Модальне вікно",
 "This is a modal window": "Це модальне вікно.",
 "This modal can be closed by pressing the Escape key or activating the close button.": "Модальне вікно можна закрити, натиснувши клавішу Esc або кнопку закриття вікна.",
 ", opens captions settings dialog": ", відкриється діалогове вікно налаштування підписів",
 ", opens subtitles settings dialog": ", відкриється діалогове вікно налаштування субтитрів",
 ", opens descriptions settings dialog": ", відкриється діалогове вікно налаштування описів",
 ", selected": ", обраний"
});