videojs.addLanguage("hu",{
 "Play": "Lejátszás",
 "Pause": "Szünet",
 "Current Time": "Aktuális időpont",
 "Duration Time": "Hossz",
 "Remaining Time": "Hátralévő idő",
 "Stream Type": "Adatfolyam típusa",
 "LIVE": "ÉLŐ",
 "Loaded": "Betöltve",
 "Progress": "Állapot",
 "Fullscreen": "Teljes képernyő",
 "Non-Fullscreen": "Normál méret",
 "Mute": "Némítás",
 "Unmute": "Némítás kikapcsolva",
 "Playback Rate": "Lejátszási sebesség",
 "Subtitles": "Feliratok",
 "subtitles off": "Feliratok kikapcsolva",
 "Captions": "Magyarázó szöveg",
 "captions off": "Magyarázó szöveg kikapcsolva",
 "Chapters": "Fejezetek",
 "You aborted the media playback": "Leállította a lejátszást",
 "A network error caused the media download to fail part-way.": "Hálózati hiba miatt a videó részlegesen töltődött le.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "A videó nem tölthető be hálózati vagy kiszolgálói hiba miatt, vagy a formátuma nem támogatott.",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "A lejátszás adatsérülés miatt leállt, vagy a videó egyes tulajdonságait a böngészője nem támogatja.",
 "No compatible source was found for this media.": "Nincs kompatibilis forrás ehhez a videóhoz."
});