videojs.addLanguage("cs",{
 "Play": "Přehrát",
 "Pause": "Pauza",
 "Current Time": "Aktuální čas",
 "Duration Time": "Doba trvání",
 "Remaining Time": "Zbývající čas",
 "Stream Type": "Stream Type",
 "LIVE": "ŽIVĚ",
 "Loaded": "Načteno",
 "Progress": "Stav",
 "Fullscreen": "Celá obrazovka",
 "Non-Fullscreen": "Zmenšená obrazovka",
 "Mute": "Ztlumit zvuk",
 "Unmute": "Přehrát zvuk",
 "Playback Rate": "Rychlost přehrávání",
 "Subtitles": "Titulky",
 "subtitles off": "Titulky vypnuty",
 "Captions": "Popisky",
 "captions off": "Popisky vypnuty",
 "Chapters": "Kapitoly",
 "You aborted the media playback": "Přehrávání videa je přerušeno.",
 "A network error caused the media download to fail part-way.": "Video nemohlo být načteno, kvůli chybě v síti.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Video nemohlo být načteno, buď kvůli chybě serveru nebo sítě nebo proto, že daný formát není podporován.",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "Váš prohlížeč nepodporuje formát videa.",
 "No compatible source was found for this media.": "Špatně zadaný zdroj videa."
});