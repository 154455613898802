videojs.addLanguage("gl",{
 "Play": "Reprodución",
 "Play Video": "Reprodución Vídeo",
 "Pause": "Pausa",
 "Current Time": "Tempo reproducido",
 "Duration Time": "Duración total",
 "Remaining Time": "Tempo restante",
 "Stream Type": "Tipo de secuencia",
 "LIVE": "DIRECTO",
 "Loaded": "Cargado",
 "Progress": "Progreso",
 "Fullscreen": "Pantalla completa",
 "Non-Fullscreen": "Pantalla non completa",
 "Mute": "Silenciar",
 "Unmute": "Non silenciado",
 "Playback Rate": "Velocidade de reprodución",
 "Subtitles": "Subtítulos",
 "subtitles off": "Subtítulos desactivados",
 "Captions": "Subtítulos con lenda",
 "captions off": "Subtítulos con lenda desactivados",
 "Chapters": "Capítulos",
 "You aborted the media playback": "Interrompeches a reprodución do vídeo.",
 "A network error caused the media download to fail part-way.": "Un erro de rede interrompeu a descarga do vídeo.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Non se puido cargar o vídeo debido a un fallo de rede ou do servidor ou porque o formato é incompatible.",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "A reproducción de vídeo interrompeuse por un problema de corrupción de datos ou porque o vídeo precisa funcións que o teu navegador non ofrece.",
 "No compatible source was found for this media.": "Non se atopou ningunha fonte compatible con este vídeo."
});