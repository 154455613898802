videojs.addLanguage("nb",{
 "Play": "Spill",
 "Pause": "Pause",
 "Current Time": "Aktuell tid",
 "Duration Time": "Varighet",
 "Remaining Time": "Gjenstående tid",
 "Stream Type": "Type strøm",
 "LIVE": "DIREKTE",
 "Loaded": "Lastet inn",
 "Progress": "Status",
 "Fullscreen": "Fullskjerm",
 "Non-Fullscreen": "Lukk fullskjerm",
 "Mute": "Lyd av",
 "Unmute": "Lyd på",
 "Playback Rate": "Avspillingsrate",
 "Subtitles": "Undertekst på",
 "subtitles off": "Undertekst av",
 "Captions": "Undertekst for hørselshemmede på",
 "captions off": "Undertekst for hørselshemmede av",
 "Chapters": "Kapitler",
 "You aborted the media playback": "Du avbrøt avspillingen.",
 "A network error caused the media download to fail part-way.": "En nettverksfeil avbrøt nedlasting av videoen.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Videoen kunne ikke lastes ned, på grunn av nettverksfeil eller serverfeil, eller fordi formatet ikke er støttet.",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "Videoavspillingen ble avbrudt på grunn av ødelagte data eller fordi videoen ville gjøre noe som nettleseren din ikke har støtte for.",
 "No compatible source was found for this media.": "Fant ikke en kompatibel kilde for dette mediainnholdet."
});