videojs.addLanguage("fr",{
 "Audio Player": "Lecteur audio",
 "Video Player": "Lecteur vidéo",
 "Play": "Lecture",
 "Pause": "Pause",
 "Replay": "Revoir",
 "Current Time": "Temps actuel",
 "Duration Time": "Durée",
 "Remaining Time": "Temps restant",
 "Stream Type": "Type de flux",
 "LIVE": "EN DIRECT",
 "Loaded": "Chargé",
 "Progress": "Progression",
 "Progress Bar": "Barre de progression",
 "progress bar timing: currentTime={1} duration={2}": "{1} de {2}",
 "Fullscreen": "Plein écran",
 "Non-Fullscreen": "Fenêtré",
 "Mute": "Sourdine",
 "Unmute": "Son activé",
 "Playback Rate": "Vitesse de lecture",
 "Subtitles": "Sous-titres",
 "subtitles off": "Sous-titres désactivés",
 "Captions": "Sous-titres transcrits",
 "captions off": "Sous-titres transcrits désactivés",
 "Chapters": "Chapitres",
 "Descriptions": "Descriptions",
 "descriptions off": "descriptions désactivées",
 "Audio Track": "Piste audio",
 "Volume Level": "Niveau de volume",
 "You aborted the media playback": "Vous avez interrompu la lecture de la vidéo.",
 "A network error caused the media download to fail part-way.": "Une erreur de réseau a interrompu le téléchargement de la vidéo.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Cette vidéo n'a pas pu être chargée, soit parce que le serveur ou le réseau a échoué ou parce que le format n'est pas reconnu.",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "La lecture de la vidéo a été interrompue à cause d'un problème de corruption ou parce que la vidéo utilise des fonctionnalités non prises en charge par votre navigateur.",
 "No compatible source was found for this media.": "Aucune source compatible n'a été trouvée pour cette vidéo.",
 "The media is encrypted and we do not have the keys to decrypt it.": "Le média est chiffré et nous n'avons pas les clés pour le déchiffrer.",
 "Play Video": "Lire la vidéo",
 "Close": "Fermer",
 "Close Modal Dialog": "Fermer la boîte de dialogue modale",
 "Modal Window": "Fenêtre modale",
 "This is a modal window": "Ceci est une fenêtre modale",
 "This modal can be closed by pressing the Escape key or activating the close button.": "Ce modal peut être fermé en appuyant sur la touche Échap ou activer le bouton de fermeture.",
 ", opens captions settings dialog": ", ouvrir les paramètres des sous-titres transcrits",
 ", opens subtitles settings dialog": ", ouvrir les paramètres des sous-titres",
 ", opens descriptions settings dialog": ", ouvrir les paramètres des descriptions",
 ", selected": ", sélectionné",
 "captions settings": "Paramètres des sous-titres transcrits",
 "subtitles settings": "Paramètres des sous-titres",
 "descriptions settings": "Paramètres des descriptions",
 "Text": "Texte",
 "White": "Blanc",
 "Black": "Noir",
 "Red": "Rouge",
 "Green": "Vert",
 "Blue": "Bleu",
 "Yellow": "Jaune",
 "Magenta": "Magenta",
 "Cyan": "Cyan",
 "Background": "Arrière-plan",
 "Window": "Fenêtre",
 "Transparent": "Transparent",
 "Semi-Transparent": "Semi-transparent",
 "Opaque": "Opaque",
 "Font Size": "Taille des caractères",
 "Text Edge Style": "Style des contours du texte",
 "None": "Aucun",
 "Raised": "Élevé",
 "Depressed": "Enfoncé",
 "Uniform": "Uniforme",
 "Dropshadow": "Ombre portée",
 "Font Family": "Famille de polices",
 "Proportional Sans-Serif": "Polices à chasse variable sans empattement (Proportional Sans-Serif)",
 "Monospace Sans-Serif": "Polices à chasse fixe sans empattement (Monospace Sans-Serif)",
 "Proportional Serif": "Polices à chasse variable avec empattement (Proportional Serif)",
 "Monospace Serif": "Polices à chasse fixe avec empattement (Monospace Serif)",
 "Casual": "Manuscrite",
 "Script": "Scripte",
 "Small Caps": "Petites capitales",
 "Reset": "Réinitialiser",
 "restore all settings to the default values": "Restaurer tous les paramètres aux valeurs par défaut",
 "Done": "Terminé",
 "Caption Settings Dialog": "Boîte de dialogue des paramètres des sous-titres transcrits",
 "Beginning of dialog window. Escape will cancel and close the window.": "Début de la fenêtre de dialogue. La touche d'échappement annulera et fermera la fenêtre.",
 "End of dialog window.": "Fin de la fenêtre de dialogue."
});