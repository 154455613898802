videojs.addLanguage("da",{
 "Play": "Afspil",
 "Pause": "Pause",
 "Current Time": "Aktuel tid",
 "Duration Time": "Varighed",
 "Remaining Time": "Resterende tid",
 "Stream Type": "Stream-type",
 "LIVE": "LIVE",
 "Loaded": "Indlæst",
 "Progress": "Status",
 "Fullscreen": "Fuldskærm",
 "Non-Fullscreen": "Luk fuldskærm",
 "Mute": "Uden lyd",
 "Unmute": "Med lyd",
 "Playback Rate": "Afspilningsrate",
 "Subtitles": "Undertekster",
 "subtitles off": "Uden undertekster",
 "Captions": "Undertekster for hørehæmmede",
 "captions off": "Uden undertekster for hørehæmmede",
 "Chapters": "Kapitler",
 "You aborted the media playback": "Du afbrød videoafspilningen.",
 "A network error caused the media download to fail part-way.": "En netværksfejl fik download af videoen til at fejle.",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Videoen kunne ikke indlæses, enten fordi serveren eller netværket fejlede, eller fordi formatet ikke er understøttet.",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "Videoafspilningen blev afbrudt på grund af ødelagte data eller fordi videoen benyttede faciliteter som din browser ikke understøtter.",
 "No compatible source was found for this media.": "Fandt ikke en kompatibel kilde for denne media."
});